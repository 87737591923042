.startbackground2{
    height: 100vh;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    margin-bottom: 3rem !important;
}
.productHeadline{
    font-size: 3rem;
    background-color: #5aba52;
    width: auto;
    padding: 1rem 2rem;
}
.headlineP{
    margin-bottom: 0;
    color: white;
    font-weight: 700;
}
.bsp{
    font-size: 3rem;
}
@media only screen and (max-width: 600px){
    .productHeadline{
        font-size: 1rem;
    }
    .bsp{
        font-size: 2rem !important;
    }
}