.navbar{
    background-color: transparent;
    display: flex;
    justify-content: center;
    z-index: 11111;
};
.navRow{
    display: flex;
    justify-content: center;
    width: 100% !important;
}