h2{
    font-size: 3rem !important;
    text-align: center;
    margin: '50px';
    line-height: 4rem;
}
.startbackground{
    height: 100vh;
    background-position: top;
    background-size: cover;
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
}
.overlay{
    background-color: rgba(0,0,0,.3); 
    width: 100%;
    height: 100vh;
    position: absolute; 
    top: 0;
    left: 0;
    z-index: 1;
}
.h1{
    font-size: 5rem;     
    color: white;
    z-index: 11111;
}
.sectionContainer{
    margin-bottom: 100px;
    text-align: center;
}
@media only screen and (max-width: 600px){
    .h1{
        font-size: 3rem !important; 
    }
}